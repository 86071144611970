import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"


const Navbar = ({ siteTitle }) => {

  return (
    <>
      <div className="row">
        <ul className="nav-menu-active">
          <li className="nav-item">
            <Link to="/for-security/" className="nav-links">
              <h1>FOR SECURITY</h1>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/for-it-ops/" className="nav-links">
              <h1>FOR IT OPS</h1>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/" className="nav-links">
              <h1>BACK</h1>
            </Link>
          </li>
        </ul>
      </div>

      <div className="backing"></div>
    </>
  )
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
